import Pet from "./assets/Pet";
import AirBlower from "./assets/blowers/AirBlower";
import {Vector2} from "./math/Vector2";
import UserInputBase from "./UserInput";
import Box from "./math/Box";
import Bill from "./assets/Bill";
import {IMovable} from "./physics/IMovable";
import {IWithPosition} from "./physics/IWithPosition";
import {IWithSpeed} from "./physics/IWithSpeed";

export default class GameScene {

    private catchingDistance: number = 10;

    constructor(public box: Box, public pet: Pet, public blowers: AirBlower[], public userInput: UserInputBase, public bills: Bill[], public caughtBills: Bill[] = []) {
    }

    /**
     * Returns the resistence that the air applies to the movement of a specific target.
     * Uses a basic approach inverting the linear speed and multiplying it by a airResistanceFactor.
     * Something like:
     *  resistance = -1 * target.speed * airResistanceFactor
     *
     * Add the vector returned by this method to the target speed make it slow down a bit at each frame.
     *
     * @param target
     * @param delta
     * @param time
     * @param airResistanceFactor
     */
    getAirResistanceSpeedDelta(target: IWithSpeed, delta: number, time: number, airResistanceFactor: number = 0.000003): Vector2 {
        const slowdownResistance = target.speed.copy();
        slowdownResistance.scale(-airResistanceFactor * target.speed.module());
        return slowdownResistance;
    }


    /**
     * Returns the combined speed delta applied by all the blowers on the target.
     * Each blower is responsible to calculate its own effect and the effects are summed into a
     * resulting vector.
     *
     * Add the vector returned by this method to the target speed to simulate the effect of the blowers at each frame.
     *
     * @param target
     * @param delta
     * @param time
     */
    getBlowersSpeedDelta(target: IWithPosition, delta: number, time: number): Vector2 {
        const blowersSpeedDelta = new Vector2();
        this.blowers.forEach(blower => {
            blowersSpeedDelta.add(blower.getSpeedDeltaOnTarget(target, delta, time));
        });
        return blowersSpeedDelta;
    }

    /**
     * Returns the speed delta generated by the user input.
     * Something like:
     *      if "A" was pressed in this frame:
     *          return new Vector2(-1,0)
     *
     * Add the vector returned by this method to the target speed to simulate the player movement
     *
     * @param delta
     * @param time
     */
    getUserInputSpeedDelta(delta: number, time: number): Vector2 {
        return this.userInput.getUserInputSpeedDelta(delta, time);
    }


    /**
     * Given a IMovable object, makes sure that it stays inside the game box.
     * Something like:
     *   for each side
     *      if movable is over the side coords and is moving towards the outside of the box:
     *          invert the movable's speed component orthogonal to the wall (x for vert walls, y for hor walls)
     *          move the movable back inside the box
     *
     * This method modifies the movable object position and speed directly.
     * @param movable
     * @param delta
     * @param time
     */
    collideWithWalls(movable: IMovable, delta: number, time: number) {
        movable.wasResetDueToWallCollision = false;

        let shouldFlipX = false;
        let newX = movable.position.x;
        if (movable.position.x > this.box.bottomRight.x) {
            if (movable.speed.x > 0) {
                shouldFlipX = true;
                newX = this.box.bottomRight.x;
                movable.wasResetDueToWallCollision = true;
            }
        }

        if (movable.position.x < this.box.topLeft.x) {
            if (movable.speed.x < 0) {
                shouldFlipX = true;
                newX = this.box.topLeft.x;
                movable.wasResetDueToWallCollision = true;
            }
        }

        if (shouldFlipX) {
            movable.speed.x *= -1;
        }
        let shouldFlipY = false;
        let newY = movable.position.y;
        if (movable.position.y > this.box.bottomRight.y) {
            if (movable.speed.y > 0) {
                shouldFlipY = true;
                newY = this.box.bottomRight.y;
                movable.wasResetDueToWallCollision = true;
            }
        }

        if (movable.position.y < this.box.topLeft.y) {
            if (movable.speed.y < 0) {
                shouldFlipY = true;
                newY = this.box.topLeft.y;
                movable.wasResetDueToWallCollision = true;
            }
        }

        if (shouldFlipY) {
            movable.speed.y *= -1;
        }

        movable.position.set(newX, newY);
    }


    /**
     * Updates the Pet Physics for the current frame.
     * @param pet
     * @param delta
     * @param time
     */
    updatePetPhysics(pet: Pet, delta: number, time: number) {
        const airResistanceSpeedDelta = this.getAirResistanceSpeedDelta(pet, delta, time);
        pet.speed.add(airResistanceSpeedDelta)
        const blowersSpeedDelta = this.getBlowersSpeedDelta(pet, delta, time);
        pet.speed.add(blowersSpeedDelta);
        const userInputSpeedDelta = this.getUserInputSpeedDelta(delta, time);
        pet.speed.add(userInputSpeedDelta);
        this.collideWithWalls(pet, delta, time);
        const deltaMove = pet.speed.copy();
        deltaMove.scale(delta);
        pet.position.add(deltaMove);
    }

    /**
     * Updates the Pet internal logical status for the current frame.
     * Useful to expose the synthesis of the status to the UI.
     * @param pet
     * @param bills
     * @param userInput
     * @param delta
     * @param time
     */
    updatePetStatus(pet: Pet, bills: Bill[], userInput: UserInputBase, delta: number, time: number) {
        pet.isTryingToCatch = userInput.isCatching();
        pet.canCatch = this.canCatchBill(pet, bills, delta, time);
    }

    /**
     * Updates the physics of the bills.
     * @param bills
     * @param delta
     * @param time
     */
    updateBillsPhysics(bills: Bill[], delta: number, time: number) {
        bills.forEach((bill) => {
            const airResistanceSpeedDelta = this.getAirResistanceSpeedDelta(bill, delta, time);
            bill.speed.add(airResistanceSpeedDelta)
            const blowersSpeedDelta = this.getBlowersSpeedDelta(bill, delta, time);
            bill.speed.add(blowersSpeedDelta);
            this.collideWithWalls(bill, delta, time);
            const deltaMove = bill.speed.copy();
            deltaMove.scale(delta);
            bill.position.add(deltaMove);
        })
    }


    /**
     * Finds the closest bill that can be caught by the pet, if any.
     * @param pet
     * @param bills
     * @param delta
     * @param time
     */
    catchableBill(pet: IMovable, bills: Bill[], delta: number, time: number): Bill | null {
        let closestBill: Bill | null = null;
        let closestBillDistance: number = Infinity;
        bills.forEach(
            bill => {
                const distanceFromBill = pet.position.scalarDistanceTo(bill.position);
                if (closestBill == null || distanceFromBill < closestBillDistance) {
                    closestBill = bill;
                    closestBillDistance = distanceFromBill;
                }
            }
        )

        if (closestBill === null) {
            return null;
        }

        if (closestBillDistance < this.catchingDistance) {
            return closestBill;
        }

        return null;
    }


    /**
     * Returns true if in this frame the pet can catch a bill
     * @param pet
     * @param bills
     * @param delta
     * @param time
     */
    canCatchBill(pet: IMovable, bills: Bill[], delta: number, time: number): boolean {
        return this.catchableBill(pet, bills, delta, time) !== null;
    }

    /**
     * Updates the game given the user input for this frame.
     * @param pet
     * @param bills
     * @param caughtBills
     * @param userInput
     * @param delta
     * @param time
     */
    handleUserInput(pet: Pet, bills: Bill[], caughtBills: Bill[], userInput: UserInputBase, delta: number, time: number) {
        if (!userInput.isCatching()) {
            return;
        }
        const catchableBill = this.catchableBill(pet, bills, delta, time);
        if (catchableBill !== null) {
            bills.splice(this.bills.indexOf(catchableBill), 1);
            caughtBills.push(catchableBill);
        }
    }

    /**
     * Updates the game for this frame
     * @param delta
     * @param time
     */
    update(delta: number, time: number) {

        this.updatePetPhysics(this.pet, delta, time);
        this.updateBillsPhysics(this.bills, delta, time);

        this.updatePetStatus(this.pet, this.bills, this.userInput, delta, time);

        this.handleUserInput(this.pet, this.bills, this.caughtBills, this.userInput, delta, time);

        this.blowers.forEach(blower => blower.update(delta, time));


    }

}